<template>
    <div>
        <!-- <AboutUs/> -->
        <HomeHeader/>
        <Yewu/>
        <!-- <div>666</div> -->
        <!-- <ItScience/> -->
        
    </div>
</template>

<script>
import HomeHeader from './home_page/HomeHeader.vue';
import AboutUs from '@/pages/AboutUs/AboutUs.vue';
import Yewu from '@/pages/Yewu/Yewu.vue';

export default {
    name: 'HomePage',
    components:{
        HomeHeader,
        AboutUs,
        Yewu
    }
}
</script>