<template>
    <el-row :gutter="20">
        <el-col :span="6" v-for="(o, index) in info" :key="index" :offset="1" class="row-con">
            <el-card :body-style="{ padding: '20px' }" shadow="hover" height="60px" class="card">
                <div class="goods-img-box">
                    <img :src="o.url" class="image">
                </div>
                <div style="padding: 14px;">
                    <span>{{ o.name }}</span>
                    <div class="bottom clearfix">
                        <!-- <div>{{ o.jianjie }}</div> -->
                        <el-button type="text" class="button" @click="o.innerVisible = true">learn more</el-button>
                        <el-dialog width="30%" :title="o.name" :visible.sync="o.innerVisible" append-to-body>
                            <p class="dialog">{{ o.detail }}</p>
                        </el-dialog>
                    </div>
                </div>
            </el-card>
        </el-col>
    </el-row>
</template>
  
<script>
export default {
    // name: 'Yewu',
    data() {
        return {
            info: [
                {
                    name: 'summer camp',
                    jianjie: "0000",
                    detail: "Usually 2-3 weeks in summer holiday, Chinese students go to UK、U.S.A、Australia to learn English language and cultural courses, visit local prestigious university, live in host families, and visit famous attractions,gain global perspective .",
                    innerVisible: false,
                    url: require('../../assets/img/summer-camp/图.png')
                },
                {
                    name: 'high school semester abroad',
                    jianjie: "1111",
                    detail: "High school students study abroad, experience the different life, enjoy the intercultural  high school education, make preparation to apply to college abroad.",
                    innerVisible: false,
                    url: require('../../assets/img/high school semester abroad/图.png')
                },
                {
                    name: 'work&travel',
                    jianjie: "2222",
                    detail: "University students work and travel abroad during their summer holiday。We offer the youths a unique experience of exploring intercultural life in different countries. ",
                    innerVisible: false,
                    url: require('../../assets/img/work&travel/图.png')
                },
                {
                    name: 'teach Mandarin abroad',
                    jianjie: "3333",
                    detail: "College students teach Mandarin abroad one year,support local students to study Chinese language.",
                    innerVisible: false,
                    url: require('../../assets/img/teach Mandarin abroad/图.png')
                },
                {
                    name: 'internship',
                    jianjie: "4444",
                    detail: "College graduates work as internship abroad.Through this program,we support the youths to practice skill in intercultural country.",
                    innerVisible: false,
                    url: require('../../assets/img/internship/图.png')
                },
            ]
        };
    }
}
</script>

<style>
.bottom {
    margin-top: 13px;
    line-height: 12px;
}

.button {
    padding: 0;
    float: right;
}

.image {
    width: 100%;
    display: block;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.row-con {
    display: flex;
    flex-flow: row wrap;
}

.row-con .card {
    height: 400px;
}

.goods-img-box {
    width: 300px;
    height: 250px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.goods-img {
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
}

.dialog {
    word-break: normal;
    overflow-wrap: break-word;
}</style>