<template>
    <div>
        <transition enter-active-class="animate__animated animate__fadeInRight" appear>
            <div v-show="aboutUs_Flag" class="contactWay">
                <p style="font-size: 30px; color:blue;text-align:center;margin-top: 30px; ">Contact Information</p>
                <div style="position: relative;left: 3%;">
                    <div class="location">
                        <span>Mailing Address</span>  <br>
                        <i>Room 202 Art Exchange Space,308 Ningxia Road,</i> <br>
                        <i>Shinan District,Qingdao City,Shandong Province,China.</i>
                    </div>
                    <div class="email">
                        <span>Email</span>  <br>
                        <i>contact@necice.com</i>
                    </div>
                    <div class="phone">
                        <span>Phone</span>  <br>
                        <i>+86 15898879782</i> <br>
                        <i>+86 13953239661</i>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {alignTransition} from "@/mixin"


export default {
    name: 'ContactWay',
    mixins:[alignTransition]

}
</script>

<style scoped>
    .contactWay{
        position: relative;
        /* left: 5%;
        width: 80%; */
        height: 100%;
        overflow: hidden;
        padding: 30px;
        /* min-width: 1366px; */
    }
    .location,.email,.phone{
        width: 33%;
        height: 200px;
        color: rgb(47, 47, 47);
        text-align: center;
        float: left;
    }
    .location:hover,.email:hover,.phone:hover{
        background-color: skyblue;
        color: rgb(255,255,255);  /* 79, 112, 211  */
    }
    .location span,.email span,.phone span{
        line-height: 100px; 
        font-size: 20px; 
        font-weight: bolder;
    }
    .location i,.email i,.phone i{
        font-size: 18px; 
    }
</style>