<template>
    <div>
        <!-- 头部 -->
        <el-header>
            <div class="header">
                <div class="logo">
                    <router-link to="/">
                        <img src="@/assets/img/logo.png" alt="" width="120px;" height="50px">
                    </router-link>
                </div>
                <div class="navigation">
                    <ul>
                        <li>
                            <router-link :to="{path:'aboutus'}" style="font-size:20px">
                                <div class="hue">About Us</div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </el-header>
    </div>
</template>

<script>

export default {
    name: 'NavigationBar',
    data() {
        return {
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
.el-header {
    background-color: #B3C0D1;
    margin: 0;
    padding: 0;
}

/deep/ .el-card__body {
    padding: 0;
}

.header {
    width: 100%;
    height: 80px;
    background-color: #b7c6d9;
    overflow: hidden;
}

/* logo */
.el-header .header .logo img {
    width: 120px;
    height: 50px;
    margin-top: 20px;
    margin-left: 150px;
    float: left;
}

/deep/ .el-header[data-v-4fd998ea] {
    text-align: left;
    background-color: #fff;
    background: rgba(R, G, B, A);
}

/* 导航栏 */
a {
    text-decoration: none;
}

.hue {
    color: #313131;
}

.navigation ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    float: right;
    margin-right: 80px;
    margin-top: 30px;
}

.navigation li {
    position: relative;
}

.navigation li {
    padding: 0 0 15px 35px;
}

.navigation div:hover,
.navigation .active,
.navigation .active:focus,
.navigation li:hover>span {
    color: white;
}

.dropdown li {
    list-style-type: none;
}

.dropdown li span {
    float: left;
    margin-right: 30px;
    margin-top: 20px;
    font-size: 15px;
    color: black;
    cursor: pointer;
}

.dropdown li span:hover {
    color: rgb(253, 131, 131);
}

.el-icon-arrow-down {
    font-size: 12px;
}

.navigation i {
    font-size: 25px;
}

.navigation i:hover {
    color: rgb(246, 103, 103);
    font-weight: bolder;
}

.header .navigation .product i {
    font-size: 20px;
    color: #313131;
    font-style: normal;
}

.header .navigation a {
    color: #313131;
}

.header .navigation a:hover {
    color: #fff;
}

.header .navigation a:active {
    color: #fff;
}

/deep/.el-dropdown-link {
    cursor: pointer;
}

/* 产品资源 */
.isActive1 {
    color: white !important;
}

/* 关于我们 */
.isActive5 {
    color: white !important;
}
</style>