import VueRouter from "vue-router"
import AboutUs from '@/pages/AboutUs/AboutUs.vue'
import HomePage from '../components/home/HomePage'
// import Yewu from '@/pages/Yewu/Yewu'

let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => { }, () => { })
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => { }, () => { })
    }
}

const router = new VueRouter({
    routes: [
        // 关于我们
        {
            path: '/aboutus',
            component: AboutUs,

            meta: {
                title: 'About Us',
                index: 2,
                PageName: true,
                keepAlive: false,
            }
        },
        // 主页
        {
            path: '/homepage',
            component: HomePage,
            meta: {
                title: 'home',
                index: 1,
                keepAlive: false,
            },
            keepalive: false,
        },
        //业务
        // {
        //     path:'/yewu',
        //     component:Yewu,
        //     meta:{
        //         title: '业务介绍',
        //         index: 2,
        //         PageName: true,
        //     }
        // },

    ],
    mode: 'history'
})

router.afterEach((to) => {
    document.title = to.meta.title || 'NECICE'
})

export default router