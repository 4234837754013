<template>
  <div>

    <!-- 轮播图 -->
    <router-view></router-view>
    <el-carousel :interval="3000" arrow="always" height="600px">
      <el-carousel-item v-for="(i, index) in img" :key="index">
        <!-- <img :src="index"> -->
        <el-image style="width: 100%;height: 100%;" :src="i" :fit="fit">
        </el-image>
      </el-carousel-item>
    </el-carousel>


  </div>
</template>


<script>
import NavigationBar from '@/components/header/NavigationBar.vue';
// import Yewu from '@/pages/Yewu/Yewu.vue';

export default {
  name: 'HeaderPage',
  components: { NavigationBar },
  data() {
    return {
      img: [
        require('@/assets/img/p1.jpg'),
        require('@/assets/img/p2.jpg'),
        require('@/assets/img/p3.jpg'),
        require('@/assets/img/p4.jpg'),
        require('@/assets/img/p5.jpg')
      ],
    }
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  background-color: #f4f4f4;
  margin: 0;
}

.el-carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

/* .el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(1) {
  background-color: #d3dce6;
  background: url(@/assets/img/p1.jpg) no-repeat 100% 80%;
  background-size: 100% 100%;
}

.el-carousel__item:nth-child(2) {
  background: url(@/assets/img/p2.jpg) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}

.el-carousel__item:nth-child(3) {
  background: url(@/assets/img/p3.jpg) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}

.el-carousel__item:nth-child(4) {
  background: url(@/assets/img/p4.jpg) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}
.el-carousel__item:nth-child(5) {
  background: url(@/assets/img/p5.jpg) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}
.el-carousel__item:nth-child(6) {
  background: url(@/assets/img/p6.jpg) no-repeat 100% 80%;
  background-size: 100% 100%;
  background-color: #d3dce6;
}

/deep/ .el-carousel__indicators {
  top: 550px;
}


.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
} */
</style>

